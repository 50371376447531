$body-bg: #f5f5f5;
$base-color: #0075b4;
$base-color2: $base-color;
$base-color3: #0076bf;
$text-color: #444649;
$Akrobat-font: Akrobat;
$OpenSans-font: "Open Sans", sans-serif;
$white: #fff;
$gray: #808080;
$text-dark: #444649;
$container-width: 1200px;
