.series-wrapper {
    .series-description {
        font-size: 16px;
        max-width: $container-width;
        margin: 0 auto 30px;

        @include media-breakpoint-down(lg) {
            padding: 0 15px;
        }
    }

    .series-go-back-holder {
        display: flex;
        margin-top: 12px;
    }

    .series-courses-title {
        max-width: $container-width;
        color: $text-dark;
        font: 700 24px/1 $font;
        margin: 52px auto 20px;

        @include media-breakpoint-down(lg) {
            padding: 0 15px;
        }
    }

    .series-courses-holder {
        max-width: $container-width;
        margin: 0 auto;

        @include media-breakpoint-down(lg) {
            padding: 0 15px;
        }
    }
}
