#frontend-component-custom-search .find-courses .search-wrapper .pgn__searchfield {
    margin-bottom: 37px;
}

.facet-dropdown-holder {
    max-width: $container-width;
    margin: 0 auto 30px;

    @include media-breakpoint-down(lg) {
        padding: 0 15px;
    }
}

.facet-dropdown-title {
    color: $text-color;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.3;
    margin: 0 0 14px;
}

.facet-dropdown {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @include media-breakpoint-down(lg) {
        justify-content: flex-start;
        gap: 16px;
    }

    div {
        margin: 0 0 15px;
        width: calc(100% / 5);
        max-width: 227px;

        @include media-breakpoint-down(lg) {
            width: auto;
            min-width: 200px;
            max-width: initial;
        }

        @include media-breakpoint-down(xs) {
            width: 100%;
        }
    }

    select {
        &[id*="input"] {
            width: 100%;
            appearance: none;
            box-shadow: none;
            border-radius: 4px;
            border: 1px solid #d7d7d7 !important;
            height: 40px;
            font-size: 16px;
            box-sizing: border-box;
            color: #959494;
            background-size: 27px 7px !important;
            padding: 0 12px !important;
            max-width: 100%;
        }
    }
}

#frontend-component-custom-search {
    @include media-breakpoint-up(xl) {
        padding-top: 75px;
    }

    .search-input {
        display: flex;
        flex-wrap: wrap;
        position: relative;
        margin: 24px 0 37px;

        @media screen and (max-width: 541px) {
            flex-direction: column;
        }
    }

    .discovery-input {
        max-width: 592px;
        width: 100%;
        font-size: 14px;
        line-height: 1;
        padding: 0 15px 0 40px;
        border-radius: 4px;
        border: 1px solid #d7d7d7;
        height: 40px;
        color: $text-color;
        font-style: normal;

        &:focus-visible {
            border-color: $grey;
        }

        &::placeholder,
        &:placeholder-shown {
            color: #959494;
            font-weight: 400;
            text-overflow: ellipsis;
        }

        @media screen and (max-width: 541px) {
            padding-right: 45px;
        }
    }

    button.custom-search {
        position: absolute;
        left: 0;
        top: 0;
        height: 40px;
        padding: 0 5px 0 12px;
        z-index: 10;
        border: 0;
        background: none;
        color: $primary;

        &:hover {
            color: $primary;

            svg {
                fill: $primary;
            }
        }

        @media screen and (max-width: 541px) {
            padding: 0 10px;
        }

        svg {
            height: 14px;
            fill: #5a5b5b;
            position: relative;
            top: -1px;
        }
    }

    .landing-title-wrapper {
        display: flex;
        justify-content: space-between;
        padding-bottom: 10px;
        margin-right: 5px;
        align-items: center;

        h3 {
            font: 600 19.2px/1.2 $font;
            margin: 0;
        }

        .show-all {
            color: #0096f2;
            font: inherit;
            font-size: 16px;
            text-decoration: none;
            transition: all .1s linear 0s;

            &:hover {
                color: #003759;
                text-decoration: underline;
            }
        }
    }

    .find-courses {
        padding: 0;
        margin: 0;
        background: $white;
        border-radius: 4px;

        @media screen and (max-width: 1300px) {
            margin: 0;
        }

        .courses {
            background: $body-bg;
            padding: 0 0 15px;
        }

        .courses-container h1.search-title {
            margin: 0 auto 30px;
            max-width: $container-width;
            font: 700 32px/1.4 $OpenSans-font;
            color: $text-color;
            text-align: left;

            @include media-breakpoint-down(lg) {
                padding: 0 15px;
            }

            @include media-breakpoint-down(sm) {
                font-size: 34px;
                line-height: 40px;
            }

            span {
                margin-left: 5px;
                color: $primary;
            }
        }

        .pgn__searchfield {
            box-sizing: border-box;
            height: 40px;
            border: none;
            max-width: $container-width;
            margin: 24px auto 46px;

            @include media-breakpoint-down(lg) {
                padding: 0 15px;
            }

            &.has-focus {
                box-shadow: none;

                form {
                    box-shadow: none;
                    border-color: $base-color;
                }
            }

            form {
                border-radius: 4px;
                border: 1px solid #d7d7d7;
                max-width: 592px;
                width: 100%;
                flex-direction: row-reverse;
            }

            .form-control {
                height: 100%;
                background: transparent;
                color: $text-color;
                font-size: 14px;
                padding-left: 5px;

                &::placeholder {
                    color: #959494;
                }
            }

            .btn {
                height: 100%;
                padding-left: 12px;
                padding-right: 5px;

                span {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                svg {
                    width: 20px !important;
                    height: 20px !important;
                }
            }
        }
    }

    .nav-tabs {
        max-width: $container-width;
        margin: 0 auto;

        @include media-breakpoint-down(lg) {
            margin: 0 15px;
        }

        .nav-link {
            font-size: 14px;
            padding-bottom: 16px;
        }
    }
}

.tab-spinner-holder {
    text-align: center;
    padding: 24px 0 20px;

    &.is-infinite-scroll {
        background: $body-bg;
    }
}

.infinite-scroll-component {
    display: flex;
    flex-direction: column;
}
