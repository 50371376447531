@import "variables";

%course-title-mod {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    height: 64px;
    color: $text-color;
    font-size: 14px;
    line-height: 1.5;
    padding: 0;
    margin: 0 0 16px;

    &:before {
        display: none;
    }
}

.find-courses {
    .courses-container {
        box-sizing: border-box;
        width: 100%;
        padding: 50px 0 0;
        margin: 0 auto;

        &:after {
            content: "";
            display: table;
            clear: both;
        }

        .courses .courses-listing .courses-listing-item.courses-listing-item--standalone-videos {
            height: auto;
            margin-bottom: 30px;
            cursor: pointer;

            .course {
                height: 100%;
                margin-bottom: 0;

                .course-image .cover-image {
                    position: relative;

                    &:hover:after {
                        opacity: 0;
                    }

                    &:after {
                        content: "";
                        display: block;
                        width: 52px;
                        height: 52px;
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        transition: opacity .3s ease-in-out;
                        background-image: url("data:image/svg+xml,%3Csvg width='52' height='52' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23a)'%3E%3Cpath d='M13 8.667v34.666a2.166 2.166 0 0 0 3.302 1.846l28.167-17.333a2.166 2.166 0 0 0 0-3.692L16.302 6.821A2.167 2.167 0 0 0 13 8.667Z' fill='%23fff'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath fill='%23fff' d='M0 0h52v52H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
                    }

                    img {
                        height: 100%;
                    }
                }
            }

            .course-info {
                height: auto;

                .course-name {
                    margin: 0;
                }

                .course-video-tag {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    color: #979797;
                    font-size: 12px;
                    font-weight: 600;
                    line-height: 1;
                    margin: 0 0 8px;

                    &:before {
                        content: "";
                        width: 24px;
                        height: 24px;
                        display: inline-block;
                        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23a)' stroke='%23979797' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='m15 10 4.553-2.276A1 1 0 0 1 21 8.618v6.764a1 1 0 0 1-1.447.894L15 14v-4ZM3 8a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8Z'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath fill='%23fff' d='M0 0h24v24H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
                    }
                }

                .course-title.course-video-title {
                    @extend %course-title-mod;
                }
            }
        }

        .wrapper-search-context {
            background: $body-bg;
            padding: 52px 0 20px;

            .search-status-label {
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 12px;
                width: 100%;
                max-width: $container-width;
                min-height: auto;
                color: $text-color;
                font-size: 24px;
                font-weight: 700;
                line-height: 1;
                margin: 0 auto;

                @include media-breakpoint-down(lg) {
                    padding: 0 15px;
                }

                @include media-breakpoint-down(sm) {
                    flex-direction: column;
                    align-items: flex-start;
                }
            }

            .search-status-label-info {
                display: flex;
                align-items: center;
                gap: 6px;
                color: $base-color3;
                font-size: 16px;
                font-weight: 400;
                line-height: 1.3;

                &:before {
                    content: "";
                    width: 20px;
                    height: 20px;
                    background-image: url("data:image/svg+xml,%3Csvg width='20' height='21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.166 6.333h1.667V8H9.166V6.333Zm0 3.333h1.667v5H9.166v-5Zm.834-7.5A8.336 8.336 0 0 0 1.667 10.5c0 4.6 3.733 8.333 8.333 8.333s8.333-3.733 8.333-8.333S14.6 2.166 10 2.166Zm0 15A6.676 6.676 0 0 1 3.333 10.5 6.676 6.676 0 0 1 10 3.833a6.676 6.676 0 0 1 6.666 6.667A6.676 6.676 0 0 1 10 17.166Z' fill='%230076BF'/%3E%3C/svg%3E");
                }
            }
        }
    }

    #filter-bar {
        margin: 10px 0 36px;

        @include media-breakpoint-down(lg) {
            padding: 0 15px;
        }

        .filters-inner {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            max-width: $container-width;
            margin: 0 auto;

            @include media-breakpoint-down(sm) {
                flex-direction: column;
                align-items: initial;
            }
        }

        .facet-list {
            display: flex;
            flex-wrap: wrap;
            list-style-type: none;
            margin: 0;
            padding: 0;

            li {
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 6px;
                margin: 0 16px 10px 0;
                position: relative;
                width: auto;
                background: $base-color;
                line-height: 1.35;

                .facet-option {
                    height: 38px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 8px;
                    color: $white;
                    background: transparent;
                    font-size: 14px;
                    font-weight: 400;
                    text-decoration: none;
                    letter-spacing: 1px;
                    border: none;
                    padding: 10px;
                    box-shadow: none;
                    position: relative;
                    -webkit-font-smoothing: antialiased;

                    svg {
                        fill: $white;
                        width: 14px;
                        height: 14px;
                        transition: all .3s ease-in-out;

                        &:hover {
                            opacity: .7;
                        }
                    }
                }
            }
        }

        .clear-filters {
            flex: 0 0 auto;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 130px;
            height: 40px;
            border: 2px solid $base-color;
            background: $base-color;
            box-shadow: none;
            border-radius: 3px;
            color: $white;
            text-align: center;
            font-size: 12px;
            font-weight: 700;
            text-transform: uppercase;
            -webkit-font-smoothing: antialiased;
            margin: 0;
            padding: 11px 21px;
            transition: all .3s ease-in-out;

            &:hover {
                color: $base-color;
                background: transparent;
            }
        }
    }

    .filters.is-animated {
        margin-bottom: 40px;

        @include media-breakpoint-down(sm) {
            margin-bottom: 15px;
        }
    }
}

.course-video-duration {
    display: flex;
    align-items: center;
    gap: 8px;
    color: $text-color;
    font-size: 12px;
    font-weight: 600;
    line-height: 1;

    &:before {
        content: "";
        width: 24px;
        height: 24px;
        display: inline-block;
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23a)' stroke='%23444649' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M3 12a9 9 0 1 0 18.001 0A9 9 0 0 0 3 12ZM12 12h3.5M12 7v5'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath fill='%23fff' d='M0 0h24v24H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
    }
}

.search-input {
    display: flex;
    flex-wrap: wrap;
    position: relative;

    @media screen and (max-width: 541px) {
        flex-direction: column;
    }
}

.discovery-input {
    padding-right: 60px;
    border-color: $gray;

    &[placeholder],
    &:placeholder-shown {
        text-overflow: ellipsis;
    }

    @media screen and (max-width: 541px) {
        padding-right: 45px;
    }
}

button.custom-search {
    z-index: 10;
    border: 0;
    background: none;
    color: $base-color2;
    padding: 0 20px;

    &:hover {
        color: $base-color;
    }

    @media screen and (max-width: 541px) {
        padding: 0 10px;
    }
}

.landing-title-wrapper {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    margin-right: 5px;
    align-items: center;

    h3 {
        font-weight: 600;
    }
}

.find-courses {
    padding: 0 40px 40px;
    margin: 0 -40px;
    background: $white;
    border-radius: 4px;

    @media screen and (max-width: 1300px) {
        margin: 0;
    }

    .courses-container h1.search-title {
        font: 700 32px/1.4 $OpenSans-font;
        color: $text-color;
        text-align: left;
        margin-bottom: 30px;

        @include media-breakpoint-down(sm) {
            font-size: 34px;
            line-height: 40px;
        }

        span {
            margin-left: 5px;
            color: $base-color;
        }
    }
}

.courses-list-holder {
    max-width: $container-width;
    margin: 0 auto;

    @include media-breakpoint-down(lg) {
        padding: 0 15px;
    }
}

.courses-listing {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0 -15px;
    padding: 0;
    text-indent: 0;
}

.courses-listing-item {
    display: block;
    box-sizing: border-box;
    height: 422px;
    width: calc(100% / 4);
    padding: 0 15px;
    margin: 0 0 28px;

    @include media-breakpoint-down(lg) {
        width: calc(100% / 3);
    }

    @include media-breakpoint-down(sm) {
        width: calc(100% / 2);
    }

    @include media-breakpoint-down(xs) {
        width: 100%;
    }

    &.is-courses-card {
        .course {
            height: 100%;
        }

        .course-info {
            height: auto;

            .course-title {
                @extend %course-title-mod;
            }
        }

        .course-name {
            padding: 24px 16px 16px;
        }

        .course-date {
            padding: 0;
        }

        .course-short-desc {
            display: -webkit-box;
            -webkit-line-clamp: 5;
            -webkit-box-orient: vertical;
            overflow: hidden;
            color: $text-color;
            font-size: 14px;
            line-height: 1.4;
            opacity: .85;
            margin: 16px 0 0;
        }
    }
}

.cover-image {
    &:before,
    .learn-more {
        transition: all .25s ease;
    }

    .learn-more {
        border: 3px solid $white;
        background: none;
        line-height: 45px;
    }
    &:hover,
    &:focus {
        &:before {
            opacity: .8;
        }
    }
}
.course-image .cover-image {
    height: 160px;
    overflow: hidden;

    // places the shadow on top of the course image while hovering over the course card
    &::before {
      left: 0;

      z-index: 1;

      position: absolute;
      top: 0;
      opacity: 0;
      background: $black;
      width: 100%;
      height: 160px;
      content: '';
    }

    img {
      width: 100%;
      height: 100%;
    }

    .learn-more {
      display: flex;
      align-items: center;
      justify-content: center;
      left: calc(50% - 100px);
      box-sizing: border-box;
      z-index: 11;
      position: absolute;
      top: 55px;
      padding: 0 20px;
      width: 200px;
      height: 50px;
      border: 3px solid $white;
      border-radius: 3px;
      background: transparent;
      color: $white;
      line-height: 1;
      text-align: center;
      opacity: 0;
      font-size: 16px;
      font-weight: 400;
      text-transform: uppercase;
    }
}
.course-info {
    padding: 0;
    height: 200px;
    font-family: "Open Sans","Helvetica Neue",Helvetica,Arial,sans-serif;

    .course-title {
        display: block;
        color: #1f1f1f;
        font-size: 14px;
        margin: 5px 0;
        height: 96px;
        overflow: hidden;
        font-weight: 700;
        text-transform: uppercase;
        word-break: break-word;
        padding: 0 15px;
        line-height: 24px;

        &:before {
            content: "";
            position: absolute;
            bottom: 10px;
            left: 0;
            background: linear-gradient(180deg, rgba($white, 0) 0, rgba($white, 1) 100%);
            height: 15px;
            width: 100%;
        }
    }
}
.course-name {
    color: #646464;
    font: normal 1.2em/1.2em "Open Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
    padding: 15px 15px 13px;
}

.course {
    background: $white;
    box-sizing: border-box;
    position: relative;
    transition: all 0.125s linear 0s;
    border-radius: 2px;
    border: 1px solid #c0c0c0;
    border-bottom: 3px solid $base-color;
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.13);

    &:hover {
        box-shadow: 0 1px 4px 0 rgb(0 118 191 / 40%);

        a {
            text-decoration: none;
        }

        .course-image .cover-image {
            &:before {
                opacity: .8;
            }

            .learn-more {
                opacity: 1;
            }
        }
    }
}

.course-date {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.45;
    padding: 2px 15px;
    color: #474747;
    font-size: 14px;
}
