#frontend-component-custom-search .course-about-page {
    background: $body-bg;
}

.course-about {
    .container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;
        max-width: $container-width;
        padding: 40px;

        @include media-breakpoint-down(sm) {
            display: block;
            padding: 15px;
        }

        & > button.register[disabled] {
            width: 100%;
            font-size: 16px;
            background: rgba($primary, .1);
            height: 42px;
            border-radius: 4px;
            border: 1px solid $primary;
            color: $primary;
            margin-bottom: 24px;
        }
    }

    .heading-group h1 {
        color: $text-dark;
        font: 700 32px/40px $font;
        text-shadow: 0 1px rgb(255, 255, 255, .6);
        margin: 0;
    }

    header.course-profile .intro-inner-wrapper {
        border-color: #ccc;

        .intro {
            word-break: break-word;

            @include media-breakpoint-down(sm) {
                width: auto;
                order: 2;
            }

            > .heading-group span {
                display: inline-block;
                background: $base-color;
                color: $white;
                text-shadow: none;
                font-size: 16px;
                line-height: normal;
                vertical-align: top;
                padding: 4px 15px;
                margin: 5px 0 0 5px;
                border-radius: 5px;
            }
        }

        > div.table {
            @include media-breakpoint-down(sm) {
                display: flex;
                flex-direction: column;
            }
        }

        .media {
            padding: 20px;

            @include media-breakpoint-down(sm) {
                width: auto;
                order: 1;
            }

            .hero {
                border: none;

                @include media-breakpoint-down(sm) {
                    margin: 0 auto;
                }

                img {
                    box-sizing: border-box;
                    display: block;
                    width: 100%;
                    height: auto;
                    margin: auto;
                    border: 1px solid #646464;
                }
            }
        }
    }

    .main-cta {
        margin-top: 25px;

        @include media-breakpoint-up(sm) {
            display: flex;
            align-items: flex-end;
        }

        > a.find-courses,
        a.register,
        a.add-to-cart,
        strong {
            color: $white;
            padding: 10px 15px;
            text-shadow: none;

            @include media-breakpoint-down(md) {
                width: 100%;
            }
        }

        span.register,
        span.add-to-cart {
            padding: 10px 15px;
            line-height: 1.6rem;
            margin-top: 0;

            @include media-breakpoint-down(md) {
                width: 100%;
                margin-right: 0;
                margin-bottom: 10px;
            }
        }

        .preferred-language-section {
            margin: 0 0 20px;

            @include media-breakpoint-up(sm) {
                margin: 0 15px 0 0;
            }

            span.preferred-language-text {
                display: block;
                font-weight: 700;
                font-size: 16px;
                margin-bottom: 12px;
                color: $text-dark;
            }

            select.lang-option-select {
                flex-grow: 1;
                max-width: 100%;
                appearance: none;
                box-shadow: none;
                border-radius: 4px;
                border: 1px solid #d7d7d7 !important;
                width: 227px;
                height: 40px;
                font-size: 16px;
                box-sizing: border-box;
                color: $text-dark;
                background-size: 27px 7px !important;
                padding: 0 30px 0 10px !important;

                @include media-breakpoint-down(xs) {
                    width: 100%;
                }

                option[data-default] {
                    color: #888;
                }
            }
        }

        .spinner-wrapper {
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;

            @include media-breakpoint-up(sm) {
                width: 117px;
            }
        }
    }

    .details {
        word-break: break-word;
        float: left;
        width: 65%;
        margin-right: 2.5%;

        @include media-breakpoint-down(sm) {
            width: 100%;
            margin: 0;
            float: none;
        }

        .teacher-image {
            .rtl & {
                margin: 0 0 0 15px !important;
            }
        }

        .inner-wrapper {
            & > section:last-child {
                margin-bottom: 0;
            }

            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
                color: $text-dark;
                line-height: 1.2;
                margin: 35px 0 17px;
                font-weight: 700;
            }

            h1 {
                font-size: 2em;
                text-align: left;
            }

            h2 {
                font-size: 1.33em;
            }

            h3 {
                font-size: 1.15em;
            }

            h4 {
                font-size: 1em;
            }

            h5 {
                font-size: .83em;
            }

            h6 {
                font-size: .67em;
            }

            p {
                color: $text-dark;
                font-size: 14px;
                line-height: 1.6;
                margin: 0 0 8px;
            }

            img,
            iframe {
                max-width: 100%;
            }

            img {
                height: auto;
            }
        }
    }

    .course-sidebar {
        width: 31.3%;
        word-break: break-word;

        @include media-breakpoint-down(sm) {
            width: 100%;
            float: none;
        }

        .important-dates {
            list-style: none;
            margin: 0;
            padding: 0 10px;

            li {
                &.important-dates-item {
                    display: flex;
                    justify-content: space-between;
                    color: $text-dark;
                    font-size: 16px;
                    margin-bottom: 13px;
                    padding-bottom: 13px;
                    border-bottom: 1px dotted #c8c8c8;

                    &:after {
                        display: none;
                    }

                    &--effort .important-dates-item-title {
                        padding-right: 15px;
                    }

                    .important-dates-item-text {
                        font-size: 16px;
                        font-weight: 700;

                        &.effort {
                            font-weight: 400;
                        }
                    }

                    .important-dates-item-title {
                        flex: none;
                        color: $text-dark;
                        font-size: 16px;
                        margin: 0;
                    }

                    .important-dates-item-links {
                        display: flex;
                        flex-direction: column;
                        font-size: 16px;

                        a {
                            margin-bottom: 10px;
                            text-align: right;

                            &:last-child {
                                margin-bottom: 0;
                            }
                        }
                    }

                    span {
                        display: block;
                        text-align: right;
                    }

                    &--languages {
                        border-bottom: 1px dotted #c8c8c8;

                        @include media-breakpoint-down(md) {
                            flex-direction: column;
                        }

                        @include media-breakpoint-down(sm) {
                            flex-direction: row;
                        }

                        .important-dates-item-title {
                            @include media-breakpoint-down(md) {
                                margin-bottom: 15px;
                            }

                            @include media-breakpoint-down(sm) {
                                margin-bottom: 0;
                            }
                        }
                    }

                    &:hover .icon,
                    &:focus .icon {
                        opacity: .6;
                    }
                }

                .pre-requisite a {
                    box-sizing: border-box;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }

                .icon {
                    margin-top: 0;
                }
            }
        }

        &.course-summary {
            padding: 16px 20px 30px;
            margin-bottom: 220px;
            border-top: none;
        }
    }

    .option-actions {
        button[disabled] {
            width: 100%;
            background: #eee;
            border: 1px solid #6f6f6f;
            box-sizing: border-box;
            color: #6f6f6f;
            display: block;
            font-size: 16px;
            letter-spacing: 1px;
            padding: 10px;
            text-align: center;
            margin-bottom: 15px;
        }

        button.register:not([disabled]),
        a {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $white;
            box-shadow: none;
            border: none;
            background-color: $primary;
            padding: 12px 18px;
            text-decoration: none;
            box-sizing: border-box;
            border-radius: 3px;
            font-size: 16px;
            line-height: 1;
            text-align: center;

            &:hover {
                background: darken($primary, 10%);
            }
        }
    }
}

.video-modal {
    &.modal,
    &.video-library,
    &.leanModal_box,
    &.entitlement-unenrollment-modal {
        @include media-breakpoint-down(sm) {
            max-width: 90%;
            transform: translateX(-50%);
            margin: 0 !important;
        }

        .inner-wrapper,
        iframe {
            box-sizing: border-box;
            max-width: 100%;
            max-height: 100%;
            width: 100%;
            padding: 10px;

            @include media-breakpoint-down(sm) {
                padding: 0;
            }
        }

        iframe {
            height: 717px;

            @include media-breakpoint-down(sm) {
                height: 580px;
            }

            @include media-breakpoint-down(xs) {
                height: 420px;
            }
        }
    }

    &.video-library {
        position: absolute;
        height: auto;
        max-width: 1022px;
        width: 90%;
        top: 50px;
        left: 50%;
        margin: 0 !important;
        transform: translateX(-50%);
        border: 1px solid #ddd;
        background: $white;
        box-shadow: none;
        padding: 25px 10px 10px;
    }

    .close-video-modal {
        position: absolute;
        right: 0;
        top: 0;
        width: 32px;
        height: 32px;
        opacity: .3;
        z-index: 99;
        cursor: pointer;

        &:hover {
            opacity: 1;
        }

        &:before,
        &:after {
            position: absolute;
            left: 15px;
            content: "";
            height: 33px;
            width: 2px;
            background-color: #333;
        }

        &:before {
            transform: rotate(45deg);
        }

        &:after {
            transform: rotate(-45deg);
        }
    }
}

.contact-us-wrapper {
    .row {
        @include media-breakpoint-up(md) {
            max-width: 100%;
        }
    }

    .help-button {
        white-space: normal;
        height: auto;

        &:hover {
            color: $link-color;
        }
    }

    .btn-primary {
        border-color: $base-color;
    }
}

.curriculum-header {
    color: $dark-grey;
    font: 400 19.2px/1.2 $font;
    text-align: center;
    margin: 19.2px 0;
}

.curriculum-text {
    color: $text-dark;
    font-size: 16px;
    line-height: 1.2;
    margin: 0;
}

.curriculum-back-link {
    color: #0096f2;
    font-size: 16px;

    &:focus,
    &:hover {
        color: #003759;
    }
}

.wrap-instructor-info.studio-view {
    text-align: right;

    a {
        font-size: 12px;
        padding: 5px 10px;
        border-radius: 5px;
        background-color: #f2f2f2;
        text-align: right;
        color: $primary;

        &:hover {
            color: $white;
            background: $primary;
            text-decoration: none;
        }
    }
}
