.content-wrapper {
    max-width: 1200px;
    margin-right: auto;
    margin-left: auto;
}

.main-container {
    width: 100%;
    box-sizing: border-box;
    flex: 1 1 auto;
    padding: 0 0 50px;

    @include media-breakpoint-up(xl) {
        padding: 30px 0 50px;
    }
}

.main-wrapper {
    background: #f5f5f5;
}

.no-course-discovery {
    width: 100%;
    display: block;
    float: left;
}

.course-about {
    background: $white;
    max-width: $container-width;
    margin: 0 auto;
    border: 1px solid #c0c0c0;
    border-radius: 4px;
}

.sr {
    clip: rect(1px, 1px, 1px, 1px);
    position: absolute;
    margin: -1px;
    height: 1px;
    width: 1px;
    border: 0;
    padding: 0;
    overflow: hidden;
    word-wrap: normal;
}

.back-to-top-btn {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $base-color3;
    width: 56px;
    height: 56px;
    position: fixed !important;
    right: 146px;
    bottom: 156px;
    opacity: 0;
    pointer-events: none;
    transition: opacity .3s ease-in-out;
    z-index: 11;

    @include media-breakpoint-down(lg) {
        right: 25px;
        bottom: 45px;
    }

    &.is-shown {
        opacity: 1;
        pointer-events: initial;
    }

    &:after {
        content: "";
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13 7.825V20h-2V7.825l-5.6 5.6L4 12l8-8 8 8-1.4 1.425-5.6-5.6Z' fill='%23fff'/%3E%3C/svg%3E");
        width: 24px;
        height: 24px;
    }

    &:focus {
        background: $base-color3;
    }
}
